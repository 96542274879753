import React from "react"
import Footer from "./footers"
import "font-awesome/css/font-awesome.css"
import "../less/main.less"
import 'prismjs/themes/prism-tomorrow.css';
import 'babel-polyfill'
import CookieConsent from "react-cookie-consent";

const botScripts = [
    "scripts.dc357b0f6dff293d5fa6.js",
    "runtime-es2015.2b896d9d0821c83c5a07.js",
    "runtime-es5.2b896d9d0821c83c5a07.js",
    "polyfills-es2015.5028ee87420ee8654e68.js",
    "polyfills-es5.4ede8a3c2fe824ce447d.js",
    "main-es2015.a4ef76e0dcd91779bc71.js",
    "main-es5.a4ef76e0dcd91779bc71.js"
];

class Layout extends React.Component {


    addScript(src) {
        let s = document.createElement('script');
        s.setAttribute('src', src);
        document.body.appendChild(s);
    }

    componentDidMount() {
        if (typeof (window) !== undefined) {
            botScripts.map(script => this.addScript(window.location.origin + '/bot/' + script));
        }
    }

    render() {
        let siteType = process.env.GATSBY_API_URL;
        return (
            <div>
                {(this.props.page === "blog") && (
                    <div className="layout-container"
                         style={{backgroundImage: `url(/static/img/bg1.png)`}}>
                        <div
                            style={{
                                margin: `0 auto`,
                                paddingTop: 0,
                            }}
                        >

                            {(siteType === "dev") && (
                                <div className="dev">
                                    <h1>DEV</h1>
                                </div>
                            )}

                            <main>{this.props.children}</main>
                        </div>
                        <Footer className={this.props.page}/>
                    </div>
                )}

                {(this.props.page !== "blog") && (
                    <div className="layout-container">
                        <div
                            style={{
                                margin: `0 auto`,
                                paddingTop: 0,
                            }}
                        >

                            {(siteType === "dev") && (
                                <div className="dev">
                                    <h1>DEV</h1>
                                </div>
                            )}

                            <main>{this.props.children}</main>
                        </div>
                        <Footer className={this.props.page}/>
                    </div>
                )}
                {(this.props.page !== "datenschutz") && (
                    <paixon-bot
                        page={this.props.angebotType !== undefined ? this.props.angebotType : this.props.page}></paixon-bot>
                )}
                {(this.props.page !== "datenschutz") && (
                    <CookieConsent
                        location="none"
                        buttonText="Akzeptieren"
                        overlay="true"
                        cookieName="paixon-cookie"
                        containerClasses="paixon-cookie-banner"
                        style={{
                            background: "#ffffff",
                            color: "#131314",
                            alignItems: "center",
                            fontSize: "14px",
                            paddingRight: "50px"
                        }}
                        buttonStyle={{
                            background: "#EBD28F",
                            border: "1px solid #131314",
                            color: "#131314",
                            fontSize: "16px",
                            marginTop: "10px",
                            padding: "8px 16px"
                        }}
                        buttonClasses='paixon-cookie-banner-button'
                        contentStyle={{flex: "1 0 auto", width: "100%", padding: "15px", margin: "0"}}
                        overlayStyle={{background: "rgba(0, 0, 0, 0.75)"}}
                    >
                        <div className="paixon-cookie-icon">
                        </div>
                        Paixon nutzt Cookies, um die Website nutzerfreundlich, sicher und effektiv zu gestalten. Cookies
                        dienen der Erhebung von Informationen über die Nutzung von Websites. Für weitere Informationen
                        lesen Sie bitte unsere <a href='https://paixon.ch/datenschutz/' target="_blank"> Hinweise zum
                        Datenschutz</a>.
                        <br/>
                        <br/>
                        <strong>Mit dem Browsing dieser Site erklären Sie sich damit einverstanden, dass Cookies
                            verwendet
                            werden.</strong>
                    </CookieConsent>
                )}
            </div>
        )
    }
}

export default Layout
