import React from "react"

const FooterLayout = ({className}) => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-xs-12 contact">
                        <div className="address">
                            <strong>Paixon GmbH</strong>
                            <br/>
                            Neunbrunnenstrasse 31
                            <br/>
                            8050 Zürich
                            <br/>
                            <br/>
                            <span>T</span>{" "}
                            <a href="tel:+41786080313" title="+41786080313">+41 (0)78 608 03 13</a>
                            <br/>
                            <span>M</span>{" "}
                            <a href="mailto:info@paixon.ch" title="info@paixon.ch">
                                info@paixon.ch
                            </a>
                            <br/>
                            <span>W</span>{" "}
                            <a href="https://paixon.ch/" title="paixon.ch" target="_blank">paixon.ch</a>
                            <br/>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12 links">
                        <div>
                            <a href="/angebot" title="Angebote" className={className === "angebot" ? "active" : ""}>
                                Angebote
                            </a>
                            <br className="hidden-xs"/>
                            <a href="/angebot/chatbot-workshop" title="Workshop"
                               className={className === "workshop" ? "active" : ""}>
                                Workshop
                            </a>
                            <br className="hidden-xs"/>
                            <a href="/team" title="Team" className={className === "team" ? "active" : ""}>
                                Team
                            </a>
                            <br className="hidden-xs"/>
                            <a href="/referenzen" title="Referenzen"
                               className={className === "reference" ? "active" : ""}>
                                Referenzen
                            </a>
                            <br className="hidden-xs"/>
                        </div>
                        <div>
                            <a href="/blog" title="Blog" className={className === "blog" ? "active" : ""}>
                                Blog
                            </a>
                            <br className="hidden-xs"/>
                            <a href="/kontakt" title="Kontakt" className={className === "kontakt" ? "active" : ""}>
                                Kontakt
                            </a>
                            <br className="hidden-xs"/>
                            <a href="/impressum" title="Impressum"
                               className={className === "impressum" ? "active" : ""}>
                                Impressum
                            </a>
                        </div>
                        <div>
                            <a href="/datenschutz" title="Datenschutz"
                               className={className === "datenschutz" ? "active" : ""}>
                                Datenschutz
                            </a>
                            <br className="hidden-xs"/>
                            <a href="/media" title="Media" className={className === "media" ? "active" : ""}>
                                Media
                            </a>
                            <br className="hidden-xs"/>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12 social-media">
                        <div className="social-media-list">
                            <a
                                href="https://www.facebook.com/paixonch"
                                title="Facebook"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="fa fa-facebook"
                            >
                                {" "}
                            </a>
                            <a
                                href="https://www.linkedin.com/company/paixon"
                                title="Linkedin"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="fa fa-linkedin"
                            >
                                {" "}
                            </a>
                            <a
                                href="https://twitter.com/paixon_ch"
                                title="Twitter"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="fa fa-twitter"
                            >
                                {" "}
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCfchye08qGD7x5eCzEk5FIA"
                                title="YouTube"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="fa fa-youtube-play"
                            >
                                {" "}
                            </a>
                        </div>
                        <div className="certificate-icons">
                            <div className="certificate-icon-cdi">
                            </div>
                            <a className="certificate-icons-bosa"
                               href="https://www.localsearch.ch/de/localsearch-holt-mit-local-ch-silber"
                               target="_blank">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterLayout
