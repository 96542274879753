import React from "react"
import PropTypes from "prop-types"

const Header = ({className, slogan, blogphoto, offersData}) => {
    return (
        <div>
            <div>
                <input type="checkbox" id="navbar-trigger" className="navbar-trigger"/>
                <nav className={className}>
                    <div className="container">
                        <div className="navbar-logo">
                            <a href="/" title="Paixon">
                            </a>
                        </div>

                        <div className="navbar-hamburger">
                            <label htmlFor="navbar-trigger" className="fa fa-bars">
                                {" "}
                            </label>
                        </div>

                        <div className="navbar-links">
                            <ul class="nav-menu nav-center">
                                <li>
                                    <a
                                        href="/angebot"
                                        className={`nav-active ${className.split('/')[0] === "offer" ? "active" : ""}`}
                                        title="Angebote"
                                    >
                                        Angebote
                                    </a>
                                    <ul class="nav-sub-menu">
                                        {offersData.sort((a, b) => a.node.order - b.node.order).map(edge => {
                                            const offers = edge.node;
                                            return (
                                                <li className="nav-sub-menu-item">
                                                    <a className={className.split('/')[1] === offers.url.split('/')[1] ? "nav-sub-menu-item-active" : ""}
                                                    href={`${offers.url}`}>
                                                        {offers.title}</a>
                                                </li>
                                            )
                                        })}
                                        <li class="nav-sub-menu-item">
                                            <a className={className.split('/')[1] === "media" ? "nav-sub-menu-item-active" : ""}
                                            href="/angebot/chatbot-workshop">Chatbot-Workshop</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a
                                        href="/team"
                                        className={className === "team" ? "active" : ""}
                                        title="Team"
                                    >
                                        Team
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/referenzen"
                                        className={className === "reference" ? "active" : ""}
                                        title="Referenzen"
                                    >
                                        Referenzen
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/blog"
                                        className={className === "blog" ? "active" : ""}
                                        title="Blog"
                                    >
                                        Blog
                                    </a>
                                </li>
                                <li className="nav-contact">
                                    <a
                                        href="/kontakt"
                                        className={className === "contact" ? "active" : ""}
                                        title="Kontakt"
                                    >
                                        Kontakt
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {slogan && (
                    < div className="container nopadding">
                        < h1 className="slogan">{slogan}</h1>
                    </div>
                )
                }
            </div>
            <div>
                <header className={className}
                        style={blogphoto}></header>
            </div>
        </div>

    )
}

Header.propTypes = {
    menuLinks: PropTypes.array,
    className: PropTypes.string,
}

Header.defaultProps = {
    menuLinks: [],
    className: "",
}

export default Header
